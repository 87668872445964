<template>
  <div>
    <v-col cols="12" class="primary mt-4 mx-0 pa-6 white--text">
      <v-row>
        <div class="text-uppercase font-weight-bold">loans</div>
      </v-row>
      <v-row class="">
        <div class="">
          View all the active and pending loans that you have sent. Request for
          loans and also see the status and reason for approval or denial of
          loan.
        </div>
      </v-row>
    </v-col>

    <div class="pa-2 mt-5">
      <div>
        <!-- <v-row v-if="memberLoans.length !== 0">
          <v-col v-for="item in memberLoans[0]" :key="item.title">
            <v-card>
              <v-card-title>
                <div class="font-weight-light text-capitalize">
                  {{ item.loan_type }} loan
                </div>
              </v-card-title>
              <v-card-text>
                <v-list two-line class="text-capitalize">
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-icon large color="primary"> mdi-cash </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        KES {{ item.amount }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        principal amount
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-avatar>
                      <v-icon large color="green"> mdi-chart-line </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.interest }} %</v-list-item-title
                      >
                      <v-list-item-subtitle> interest </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-avatar>
                      <v-icon large color="purple"> mdi-cash-refund </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.repaid }}
                      </v-list-item-title>
                      <v-list-item-subtitle> repayment </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>

                <div class="d-flex">
                  <v-spacer></v-spacer>
                  <v-dialog width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" v-on="on" v-bind="attrs"
                        >reports <v-icon right>mdi-chevron-right</v-icon></v-btn
                      >
                    </template>

                    <v-card width="500">
                      <v-card-title>
                        <p class="text-uppercase mx-auto">
                          reports for {{ item.type }} loan
                        </p>
                      </v-card-title>
                      <v-divider></v-divider>
                      <v-card-text class="mt-3">
                        <v-list>
                          <v-list-item v-for="n in item.record" :key="n.date">
                            <v-list-item-icon>
                              <v-icon> mdi-circle-small </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ new Date(n.date) | moment("Do MMMM YYYY") }}
                              </v-list-item-title>
                              <v-list-item-subtitle class="error--text">
                                - KES {{ n.amount }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row> -->
      </div>

      <div class="text-h5 text-capitalize mt-2">recent loans</div>
      <!-- sort by date -->
      <v-card-title>
        <v-menu
          v-model="fromMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="from"
              label="From Date"
              prepend-icon="mdi-calendar"
              outlined
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="from"
            @input="fromMenu = false"
          ></v-date-picker>
        </v-menu>

        <v-menu
          v-model="toMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="to"
              label="To Date"
              outlined
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="to" @input="toMenu = false"></v-date-picker>
        </v-menu>
      </v-card-title>

      <v-card-title>
        <v-icon>mdi-text-search</v-icon>
        <div class="text-uppercase">Search Results</div>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-text-field
          append-icon="mdi-magnify"
          outlined
          label="Search"
          dense
          v-model="search"
        ></v-text-field>
      </v-card-title>

      <v-card color="transparent" flat>
        <v-row>
          <v-col>
            <v-dialog width="500" v-model="dialog">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" color="primary" class="mb-2">
                  <v-icon left>mdi-plus</v-icon> apply loan</v-btn
                >
              </template>

              <v-card width="500" class="mx-auto">
                <v-card-title>
                  <div class="mx-auto text-uppercase font-weight-light">
                    apply loan
                  </div>
                </v-card-title>

                <v-card-text>
                  <v-form v-model="form" ref="form">
                    <v-text-field
                      label="Amount"
                      outlined
                      dense
                      v-model="amount"
                      :rules="[rules.required]"
                    ></v-text-field>

                    <v-autocomplete
                      label="Loan Type"
                      outlined
                      dense
                      v-model="type"
                      :items="['Mortgage', 'Car', 'Salary']"
                      :rules="[rules.required]"
                    />

                    <v-menu
                      v-model="fromDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fromDate"
                          label="From Date"
                          outlined
                          readonly
                          :rules="[rules.required]"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fromDate"
                        @input="fromDateMenu = false"
                      ></v-date-picker>
                    </v-menu>

                    <v-menu
                      v-model="toDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="toDate"
                          label="To Date"
                          outlined
                          :rules="[rules.required]"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="toDate"
                        @input="toDateMenu = false"
                      ></v-date-picker>
                    </v-menu>

                    <div class="d-flex">
                      <v-btn color="primary" @click="dialog = false"
                        >cancel</v-btn
                      >
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        :loading="loading"
                        :disabled="!form"
                        @click="requestLoan()"
                        >apply</v-btn
                      >
                    </div>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-dialog>
            <v-data-table
              :headers="headers"
              :items="memberLoans"
              :search="search"
              class="text-capitalize elevation-10"
              no-data-text="No results for the current date"
            >
              <template v-slot:[`item.start_date`]="{ item }">
                {{ new Date(item.start_date) | moment("ddd, MMMM Do YYYY") }}
              </template>
              <template v-slot:[`item.end_date`]="{ item }">
                {{ new Date(item.end_date) | moment("ddd, MMMM Do YYYY") }}
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <span class="green--text" v-if="item.status === 'approved'">
                  {{ item.status }}
                </span>
                <span class="red--text" v-if="item.status === 'denied'">
                  {{ item.status }}
                </span>
                <span v-if="item.status === 'pending'">
                  {{ item.status }}
                </span>
              </template>
              <template v-slot:[`item.reason`]="{ item }">
                {{ item.reason == null ? "no response yet" : item.reason }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </div>
    <v-snackbar
      v-model="snackBar"
      :color="snackBarData.success ? 'success' : 'error'"
    >
      <div class="text-capitalize text-center">
        {{ snackBarData.message }}
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { loanStore } from "@/stores/loans";
import { userStore } from "@/stores/user";

export default {
  data() {
    return {
      toMenu: false,
      fromMenu: false,
      from: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      to: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      amount: null,
      type: "",
      fromDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      toDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      fromDateMenu: false,
      toDateMenu: false,
      search: "",
      stepper: "1",
      headers: [
        {
          text: "start date",
          value: "start_date",
          class: "text-uppercase black--text",
        },
        {
          text: "end date",
          value: "end_date",
          class: "text-uppercase black--text",
        },
        {
          text: "type",
          value: "loan_type",
          class: "text-uppercase black--text",
        },
        {
          text: "status",
          value: "status",
          class: "text-uppercase black--text",
        },
        {
          text: "reason",
          value: "reason",
          class: "text-uppercase black--text",
        },
      ],
      dialog: false,
      form: false,
      rules: {
        required: (v) => !!v || "Required",
      },
      loading: false,
      snackBar: false,
      snackBarData: {
        success: null,
        message: "",
      },
    };
  },
  computed: {
    ...mapState(loanStore, ["memberLoans"]),
    ...mapState(userStore, ["user"]),
  },
  methods: {
    ...mapActions(loanStore, ["getSingleMemberLoans", "createLoan"]),
    requestLoan() {
      this.loading = true;
      this.createLoan({
        amount: this.amount,
        interest: 10,
        start_date: this.fromDate,
        end_date: this.toDate,
        status: "pending",
        reason: "",
        loan_type: this.type.toLowerCase(),
        user_id: this.user.id,
      })
        .then(() => {
          this.showSnackBar(true, "Loan request sent succesfully");
          this.$refs.form.reset();
          this.getSingleMemberLoans();
        })
        .catch((err) => {
          this.showSnackBar(false, err.response.data.message);
        })
        .finally(() => {
          this.loading = false;
          this.dialog = false;
        });
    },
    showSnackBar(success, message) {
      this.snackBar = true;
      this.snackBarData.success = success;
      this.snackBarData.message = message;
    },
  },
  created() {
    this.getSingleMemberLoans();
  },
};
</script>

<style></style>
